//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-776:_8340,_2428,_1396,_4148,_2016,_3028,_196,_884;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-776')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-776', "_8340,_2428,_1396,_4148,_2016,_3028,_196,_884");
        }
      }catch (ex) {
        console.error(ex);
      }