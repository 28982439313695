//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-776:_4720,_6472,_1939,_8908,_5400,_4548,_4672,_7392;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-776')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-776', "_4720,_6472,_1939,_8908,_5400,_4548,_4672,_7392");
        }
      }catch (ex) {
        console.error(ex);
      }